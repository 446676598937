[data-wf-efmya-type='badge-value'] {
	border-radius: 10px;
	padding: 2px 18px;
	text-align: center;
	font-weight: normal;
	color: black;
	background-color: #ffffff;
	cursor: pointer;
}

[data-wf-efmya-badge='upcoming'] {
	color: white;
	background-color: #4168b4;
}

[data-wf-efmya-badge='late'] {
	color: white;
	background-color: #ba0600;
}

[data-wf-efmya-type='date-value'] {
	margin-right: 12px;
}

[data-wf-efmya-tag='basic-payment'] {
	text-align: center;
	padding: 1rem 0;
	margin: 0;
}

[data-wf-efmya-tag='basic-support'] {
	padding-left: 12px;
	padding-right: 12px;
	color: black;
}
[data-wf-efmya-tag='basic-support'] button span {
	color: inherit;
}

[data-wf-efmya-tag='basic-payment']:last-child {
	border-bottom: none;
}

[data-wf-efmya-basic-payment-label-container] {
	display: flex;
	justify-content: center;
}

[data-wf-efmya-basic-payment-label] {
	align-self: center;
	padding-left: 0.5rem;
}

[data-wf-efmya-basic-table-label] h2 {
	color: black;
}

[data-wf-efmya-title] {
	font-size: 1rem;
	font-weight: 700;
}

button[data-wf-efmya-header-utilities='support']:hover {
	background: transparent !important;
	border-color: transparent !important;
	text-decoration: underline;
}

[data-wf-efmya-header-utilities='support'],
[data-wf-efmya-header-utilities='signout'] {
	margin-right: 15px;
}

[data-wf-efmya-basic-table-header-toolbar] {
	box-sizing: border-box;
	background-color: #f7f7f6;
	border: 1px solid #e9e9e9;
	padding: 0.625rem;
}

[data-wf-efmya-basic-table-header-pagination] {
	display: flex;
	justify-content: flex-end;
}

/* Start of Basic Footer Css */
[data-wf-efmya-tag='basic-footer'] {
	padding: 1rem 0 2.5rem;
	text-align: center;
	background-color: inherit;
	margin-top: 2.5rem;
}

[data-wf-efmya-tag='basic-footer'] > [data-wf-efmya-instructions] {
	text-align: left;
	display: flex;
}
:global [data-wf-efmya-tag='basic-footer'] > [data-wf-efmya-copyright] p.BasicText {
	color: #555;
	font-size: 0.75rem;
	line-height: normal;
	margin-bottom: 0;
}
[data-wf-efmya-tag='basic-footer'] > [data-wf-efmya-links] {
	border-top: 1px solid #dfdfdf;
	margin: 2.5rem auto 2.5rem;
}

[data-wf-efmya-tag='basic-footer'] > [data-wf-efmya-links] > ul {
	max-width: 35rem;
	margin: 0 auto 2.5rem;
	padding: 0;
}

[data-wf-efmya-tag='basic-footer'] > [data-wf-efmya-links] > ul > li {
	padding-right: 1.25rem;
	padding-top: 1rem;
	margin: 0;
	display: inline-block;
	list-style-type: none;
	color: #555;
}

[data-wf-efmya-tag='basic-footer'] > [data-wf-efmya-links] > ul > li > span > button > span {
	text-decoration: underline !important;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
	[data-wf-efmya-tag='basic-footer'] > [data-wf-efmya-instructions] {
		margin: 0.3rem;
	}
	[data-wf-efmya-tag='basic-footer'] {
		padding: 1.5rem 0 1rem;
		margin-top: 0;
	}
}
/* End of Basic Footer Css */

[data-wf-efmya-tag='basic-menu'] {
	display: inline-block;
	min-width: 80px;
	vertical-align: middle;
}

[data-wf-efmya-header-utility] div span > span {
	color: initial;
}

[data-wf-efmya-header-utility] > * {
	padding: 2px 10px;
	display: inline-block;
	height: 30px;
	line-height: 2;
}

[data-wf-efmya-header-utility] > div:last-child {
	border: none;
	padding-left: 10px;
}

button[data-wf-efmya-header-utilities='support'] {
	padding: 0;
	margin: 0;
}

button[data-wf-efmya-basic-action-type='link'],
button[data-wf-efmya-basic-action-type='link']:hover {
	text-decoration: underline;
	cursor: pointer;
}

[data-wf-efmya-tag='basic-navigator'] span[role='img'] {
	visibility: hidden;
}

[data-wf-efmya-media='print'] {
	display: none;
}

[data-wf-efmya-tag='basic-print-button'] {
	text-align: right;
	margin: 10px 0;
}

[data-wf-efmya-text-area] {
	overflow: scroll;
	border: 1px solid lightgray;
	padding: 1em;
}

[data-efyma-type-basic-label-table-header] h2 {
	margin-bottom: 2px;
}

@media print {
	[data-wf-efmya-tag='basic-print-button'] {
		display: none;
	}
	[data-wf-efmya-media] {
		display: none;
	}

	[data-wf-efmya-media='print'] {
		display: block;
	}
}

[data-wf-efmya-choice-value] > div {
	display: inline-block;
	margin-left: 8px;
}

[data-wf-efmya-choice-value] > div > input {
	width: auto;
}

[data-wf-efmya-choice-label-content] > div {
	display: inline-block;
	margin-right: 8px;
}

[data-wf-efmya-choice-label-content] > div > label {
	display: none;
}

input[hidden] {
	display: none !important;
}

[data-wf-efmya-system-messages-item] {
	display: inline-block;
}

[data-wf-efmya-system-messages-item] ul {
	margin-left: 20px !important;
}

[data-wf-efmya-system-messages-item] p {
	margin: 0 !important;
}

[data-wf-efmya-system-messages-item] li {
	list-style: disc;
}

[data-wf-efmya-tag='basic-label-choice'] [data-wf-efmya-class='validation-section'] {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 0.75rem 0 0.25rem 0;
}

[data-wf-efmya-tag='basic-label-choice']
	[data-wf-efmya-class='validation-message']
	span:first-child {
	margin-right: 4px;
	vertical-align: middle;
}

[data-wf-efmya-tag='basic-label-choice']
	[data-wf-efmya-class='validation-section'][data-wf-efmya-validation-section-hidden] {
	display: none;
}

[data-wf-efmya-tag='basic-label-choice']
	[data-wf-efmya-class='validation-section']
	[data-wf-efmya-class='validation-message'] {
	color: #bb0700;
	font-size: 0.75rem;
}

[data-wf-efmya-tag='basic-label-choice'] legend[class~='wf-select__label--inline'] {
	float: left;
}

[data-wf-efmya-basic-loading] {
	position: relative;
}

[data-wf-efmya-basic-loading] [data-wf-efmya-class='waitMessageContainer'] {
	position: absolute;
	min-height: 100vh;
	min-width: 100%;
}

[data-wf-efmya-layout='basic-message'] p {
	margin-bottom: unset;
}
[data-wf-efmya-tag='basic-message'] span,
[data-wf-efmya-tag='basic-message'] svg {
	vertical-align: middle;
}

[data-wf-efmya-tag='basic-message'] svg {
	margin: 0 12px 0 0;
}

[data-wf-efmya-choice-value][data-wf-efmya-display='inline'] {
	padding-right: 24px;
}

[data-wf-efmya-choice-value] {
	padding-top: 0.4rem;
}

[data-wf-efmya-class='debug'] {
	padding: 4px;
	background-color: #555;
	color: #dfdfdf;
}

[data-wf-rddc-debug-class='DebugHighlightWidth'] {
	border: 2px solid red;
}

[data-basic-label-choice-fieldset] > div {
	margin-left: 0 !important;
}
